import { MaterialGame } from './MaterialGame'
import { Rules } from '../Rules'
import {
  isDeleteItem,
  isMoveItem,
  isShuffle,
  isStartPlayerTurn,
  isStartSimultaneousRule,
  LocalMoveType,
  MaterialMove,
  MaterialMoveRandomized,
  MaterialMoveView,
  MoveKind,
  RuleMoveType
} from './moves'
import { Material, MaterialMutator } from './items'
import { RandomMove } from '../RandomMove'
import { MaterialRulesPart, MaterialRulesPartCreator } from './rules'
import { LocationStrategy } from './location'
import { Undo } from '../Undo'
import { Action } from '../Action'
import { GameMemory, PlayerMemory } from './memory'

export abstract class MaterialRules<Player extends number = number, MaterialType extends number = number, LocationType extends number = number>
  extends Rules<MaterialGame<Player, MaterialType, LocationType>, MaterialMove<Player, MaterialType, LocationType>, Player>
  implements RandomMove<MaterialMove<Player, MaterialType, LocationType>, MaterialMoveRandomized<Player, MaterialType, LocationType>>,
    Undo<MaterialGame<Player, MaterialType, LocationType>, MaterialMove<Player, MaterialType, LocationType>, Player> {

  material(type: MaterialType): Material<Player, MaterialType, LocationType> {
    return new Material(type, Array.from((this.game.items[type] ?? []).entries()).filter(entry => entry[1].quantity !== 0))
  }

  abstract readonly rules: Record<number, MaterialRulesPartCreator<Player, MaterialType, LocationType>>

  readonly locationsStrategies: Partial<Record<MaterialType, Partial<Record<LocationType, LocationStrategy<Player, MaterialType, LocationType>>>>> = {}
  readonly materialLocations: Partial<Record<MaterialType, LocationType[]>> = {}

  get players(): Player[] {
    return this.game.players
  }

  protected getMemory(player?: Player) {
    return player === undefined ? new GameMemory(this.game) : new PlayerMemory(this.game, player)
  }

  remind<T = any>(key: keyof any, player?: Player): T {
    return this.getMemory(player).remind(key)
  }

  get rulesStep(): MaterialRulesPart<Player, MaterialType, LocationType> | undefined {
    if (!this.game.rule) return
    const RulesStep = this.rules[this.game.rule.id]
    return new RulesStep(this.game, type => this.material(type))
  }

  mutator(type: MaterialType): MaterialMutator<Player, MaterialType, LocationType> {
    return new MaterialMutator(type, this.game.items[type]!, this.locationsStrategies[type], this.materialLocations[type])
  }

  delegate(): Rules<MaterialGame<Player, MaterialType, LocationType>, MaterialMove<Player, MaterialType, LocationType>, Player> | undefined {
    return this.rulesStep
  }

  randomize(
    move: MaterialMove<Player, MaterialType, LocationType>
  ): MaterialMove<Player, MaterialType, LocationType> & MaterialMoveRandomized<Player, MaterialType, LocationType> {
    if (move.kind === MoveKind.ItemMove) {
      return this.mutator(move.itemType).randomize(move)
    }
    return move
  }

  play(
    move: MaterialMoveRandomized<Player, MaterialType, LocationType> | MaterialMoveView<Player, MaterialType, LocationType>
  ): MaterialMove<Player, MaterialType, LocationType>[] {

    const consequences: MaterialMove<Player, MaterialType, LocationType>[] = []
    const rulesStep = this.rulesStep
    switch (move.kind) {
      case MoveKind.ItemMove:
        if (rulesStep) {
          consequences.push(...rulesStep.beforeItemMove(move))
        }
        if (!this.game.items[move.itemType]) this.game.items[move.itemType] = []
        const mutator = this.mutator(move.itemType)
        mutator.applyMove(move)
        if (this.game.droppedItem && (isMoveItem(move) || isDeleteItem(move))
          && this.game.droppedItem.type === move.itemType && move.itemIndex === this.game.droppedItem.index) {
          delete this.game.droppedItem
        }
        if (rulesStep) {
          consequences.push(...rulesStep.afterItemMove(move))
        }
        break
      case MoveKind.RulesMove:
        const rule = this.game.rule
        if (rulesStep) {
          consequences.push(...rulesStep.onRuleEnd(move))
        }
        switch (move.type) {
          case RuleMoveType.StartPlayerTurn:
            this.game.rule = { id: move.id, player: move.player }
            break
          case RuleMoveType.StartSimultaneousRule:
            this.game.rule = { id: move.id, players: move.players ?? this.game.players }
            break
          case RuleMoveType.EndPlayerTurn:
            if (this.game.rule?.players) {
              this.game.rule.players = this.game.rule.players.filter(player => player !== move.player)
            }
            break
          case RuleMoveType.StartRule:
            this.game.rule = { id: move.id, player: this.game.rule?.player }
            break
          case RuleMoveType.EndGame:
            delete this.game.rule
            break
        }
        if (move.type !== RuleMoveType.EndGame) {
          consequences.push(...this.rulesStep!.onRuleStart(move, rule))
        }
        break
      case MoveKind.CustomMove:
        if (rulesStep) {
          consequences.push(...rulesStep.onCustomMove(move))
        }
        break
      case MoveKind.LocalMove:
        switch (move.type) {
          case LocalMoveType.DisplayRules:
            this.game.rulesDisplay = move.rulesDisplay
            break
          case LocalMoveType.CloseRulesDisplay:
            delete this.game.rulesDisplay
            break
          case LocalMoveType.DropItem:
            this.game.droppedItem = move.item
            break
          case LocalMoveType.SetTutorialStep:
            this.game.tutorialStep = move.step
            delete this.game.tutorialPopupClosed
            delete this.game.tutorialStepComplete
            break
          case LocalMoveType.CloseTutorialPopup:
            this.game.tutorialPopupClosed = true
        }
    }

    return consequences
  }

  canUndo(action: Action<MaterialMove<Player, MaterialType, LocationType>, Player>,
          consecutiveActions: Action<MaterialMove<Player, MaterialType, LocationType>, Player>[]): boolean {
    for (let i = consecutiveActions.length - 1; i >= 0; i--) {
      const consecutiveAction = consecutiveActions[i]
      if (consecutiveAction.playerId === action.playerId || this.actionActivatesPlayer(consecutiveAction)) {
        return false
      }
    }
    return !this.actionBlocksUndo(action)
  }

  protected actionBlocksUndo(action: Action<MaterialMove<Player, MaterialType, LocationType>, Player>): boolean {
    for (let i = action.consequences.length - 1; i >= 0; i--) {
      if (this.moveBlocksUndo(action.consequences[i])) {
        return true
      }
    }
    return this.moveBlocksUndo(action.move)
  }

  protected actionActivatesPlayer(action: Action<MaterialMove<Player, MaterialType, LocationType>, Player>): boolean {
    for (let i = action.consequences.length - 1; i >= 0; i--) {
      if (this.moveActivatesPlayer(action.consequences[i])) {
        return true
      }
    }
    return this.moveActivatesPlayer(action.move)
  }

  protected moveBlocksUndo(move: MaterialMove<Player, MaterialType, LocationType>): boolean {
    return this.moveActivatesPlayer(move) || this.isRandomMove(move)
  }

  protected moveActivatesPlayer(move: MaterialMove<Player, MaterialType, LocationType>): boolean {
    return isStartPlayerTurn(move) || isStartSimultaneousRule(move)
  }

  isUnpredictableMove(_move: MaterialMove<Player, MaterialType, LocationType>, _player: Player): boolean {
    return false // TODO: isThrowDice(move)
  }

  protected isRandomMove(move: MaterialMove<Player, MaterialType, LocationType>): boolean {
    return isShuffle(move) // TODO: || isThrowDice(move)
  }

  restoreLocalMoves(game: MaterialGame<Player, MaterialType, LocationType>) {
    this.game.rulesDisplay = game.rulesDisplay
  }

  isOver(playerIds?: Player[]): boolean {
    return super.isOver(playerIds ?? this.game.players)
  }
}

export interface MaterialRulesCreator<P extends number = number, M extends number = number, L extends number = number> {
  new(state: MaterialGame<P, M, L>, client?: { player?: P }): MaterialRules<P, M, L>
}
