import { useQuery } from '@apollo/client'
import { GET_MESSAGE_AUTHOR } from '../api/Client/GameClientAPI'
import { GamePageState } from '../Store'
import { useSelector } from 'react-redux'
import { User } from '../User'

export function useMessageAuthor(author: string) {
  const players = useSelector((state: GamePageState) => state.players)
  const player = players.find(p => p.userId === author)
  const { data } = useQuery<{ user: User }>(GET_MESSAGE_AUTHOR, { variables: { id: author }, skip: !!player })
  const avatar = player?.avatar ?? data?.user.avatar
  const name = player?.name ?? data?.user.name
  return { avatar, name }
}
