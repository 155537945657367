import { ItemPosition, MaterialItem } from './MaterialItem'
import { Coordinates, Location } from '../location'
import equal from 'fast-deep-equal'
import { CreateItem, DeleteItem, ItemMove, ItemMoveType, MoveItem, MoveKind, Shuffle } from '../moves'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import orderBy from 'lodash/orderBy'
import sumBy from 'lodash/sumBy'

type ItemEntry<P extends number = number, L extends number = number> = [number, MaterialItem<P, L>]

export class Material<P extends number = number, M extends number = number, L extends number = number> {

  readonly type: M
  readonly entries: ItemEntry<P, L>[]
  private readonly processMove?: (move: ItemMove<P, M, L>) => void

  constructor(type: M, items: ItemEntry<P, L>[], processMove?: (move: ItemMove<P, M, L>) => void) {
    this.type = type
    this.entries = items
    this.processMove = processMove
  }

  private new(entries: ItemEntry<P, L>[]): Material<P, M, L> {
    return new Material(this.type, entries, this.processMove)
  }

  getItems(): MaterialItem<P, L>[]
  getItems<T>(mapFn: (item: MaterialItem<P, L>) => T): T[]
  getItems<T>(mapFn?: (item: MaterialItem<P, L>) => T): (MaterialItem<P, L> | T)[] {
    return this.entries.map(entry => mapFn ? mapFn(entry[1]) : entry[1])
  }

  getItem(index: number): MaterialItem<P, L> | undefined
  getItem(predicate?: (item: MaterialItem<P, L>) => boolean): MaterialItem<P, L> | undefined
  getItem(arg?: number | ((item: MaterialItem<P, L>) => boolean)): MaterialItem<P, L> | undefined {
    if (typeof arg === 'number') {
      const entry = this.entries.find(entry => entry[0] === arg)
      return entry ? entry[1] : undefined
    } else if (typeof arg === 'function') {
      const entries = this.entries.filter(([, item]) => arg(item))
      return entries.length ? entries[0][1] : undefined
    } else {
      return this.entries.length ? this.entries[0][1] : undefined
    }
  }

  getIndex(): number {
    return this.entries[0]?.[0] ?? -1
  }

  getIndexes(): number[] {
    return this.entries.map(entry => entry[0])
  }

  index(index: number): Material<P, M, L> {
    const item = this.entries.find(([i]) => i === index)
    return this.new(item ? [item] : [])
  }

  indexes(indexes: number[]): Material<P, M, L> {
    const items = this.entries.filter(([i]) => indexes.includes(i))
    return this.new(items)
  }

  get length(): number {
    return this.entries.length
  }

  getQuantity(): number {
    return sumBy(this.entries, ([, item]) => item.quantity ?? 1)
  }

  filter(predicate: (item: MaterialItem<P, L>, index: number) => boolean): Material<P, M, L> {
    return this.new(this.entries.filter(([index, item]) => predicate(item, index)))
  }

  id<Id extends string | number | Record<string, any>>(arg?: Id | ((id?: Id) => boolean)): Material<P, M, L> {
    return this.filter(({ id }) => typeof arg === 'function' ? arg(id) : equal(id, arg))
  }

  location(arg: L | ((location: Location<P, L>) => boolean)): Material<P, M, L> {
    return this.filter(({ location }) => typeof arg === 'function' ? arg(location) : location.type === arg)
  }

  rotation(arg: ((rotation?: Partial<Coordinates>) => boolean)): Material<P, M, L> {
    return this.filter(({ rotation }) => arg(rotation))
  }

  player(arg?: P | ((player?: P) => boolean)): Material<P, M, L> {
    return this.location(({ player }) => typeof arg === 'function' ? arg(player) : player === arg)
  }

  locationId<Id extends string | number | Array<any>>(arg: Id | ((id?: Id) => boolean)): Material<P, M, L> {
    return this.location(({ id }) => typeof arg === 'function' ? arg(id) : equal(id, arg))
  }

  parent(arg?: number | ((parent?: number) => boolean)): Material<P, M, L> {
    return this.location(({ parent }) => typeof arg === 'function' ? arg(parent) : equal(parent, arg))
  }

  minBy(selector: (item: MaterialItem<P, L>) => number): Material<P, M, L> {
    const min = minBy(this.entries, entry => selector(entry[1]))
    return this.new(min ? [min] : [])
  }

  sort(selector: (item: MaterialItem<P, L>) => number): Material<P, M, L> {
    const orderedItems = orderBy(this.entries, entry => selector(entry[1]))

    return this.new(orderedItems)
  }

  limit(count: number): Material<P, M, L> {
    return this.new(this.entries.slice(0, count))
  }

  maxBy(selector: (item: MaterialItem<P, L>) => number): Material<P, M, L> {
    const max = maxBy(this.entries, entry => selector(entry[1]))
    return this.new(max ? [max] : [])
  }

  private process<T extends ItemMove<P, M, L>>(moves: T[]): T[] {
    if (this.processMove) {
      for (const move of moves) {
        this.processMove(move)
      }
    }
    return moves
  }

  createItem(item: MaterialItem<P, L>): CreateItem<P, M, L> {
    return this.createItems([item])[0]
  }

  createItems(items: MaterialItem<P, L>[]): CreateItem<P, M, L>[] {
    return this.process(items.map(item => ({
      kind: MoveKind.ItemMove,
      type: ItemMoveType.Create,
      itemType: this.type,
      item
    })))
  }

  deleteItem(quantity?: number): DeleteItem<M> {
    if (!this.length) throw new Error('You are trying to delete an item that does not exists')
    return this.limit(1).deleteItems(quantity)[0]
  }

  deleteItems(quantity?: number): DeleteItem<M>[] {
    return this.process(this.entries.map(entry => {
      const move: DeleteItem<M> = {
        kind: MoveKind.ItemMove,
        type: ItemMoveType.Delete,
        itemType: this.type,
        itemIndex: entry[0]
      }
      if (quantity) move.quantity = quantity
      return move
    }))
  }

  moveItem(arg: ((item: MaterialItem<P, L>) => Partial<ItemPosition<P, L>>) | Partial<ItemPosition<P, L>>, quantity?: number): MoveItem<P, M, L> {
    if (!this.length) throw new Error('You are trying to move an item that does not exists')
    return this.limit(1).moveItems(arg, quantity)[0]
  }

  moveItems(arg: ((item: MaterialItem<P, L>) => Partial<ItemPosition<P, L>>) | Partial<ItemPosition<P, L>>, quantity?: number): MoveItem<P, M, L>[] {
    const getPosition = typeof arg === 'function' ? arg : () => arg
    return this.process(this.entries.map(entry => {
      const position = getPosition(entry[1])
      if (position.rotation && !position.rotation.x && !position.rotation.y && !position.rotation.z) delete position.rotation
      const move: MoveItem<P, M, L> = {
        kind: MoveKind.ItemMove,
        type: ItemMoveType.Move,
        itemType: this.type,
        itemIndex: entry[0],
        position
      }
      if (quantity) move.quantity = quantity
      return move
    }))
  }

  shuffle(): Shuffle<M> {
    return this.process([{
      kind: MoveKind.ItemMove,
      type: ItemMoveType.Shuffle,
      itemType: this.type,
      indexes: this.entries.map(entry => entry[0])
    }])[0]
  }
}
