import { gql, useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { gameStateLoaded } from '../../Actions'
import { GameData } from '../../Types'
import { AVATAR_INFO, PLAYERS_TIME } from './GameClientAPI'
import { GameNotificationsListener } from './GameNotificationsListener'

const GET_GAME = gql`
  query Game($id: String!) {
    time
    game(id: $id) {
      id
      players {
        id userId name avatar {...AvatarInfo} gamePointsDelta tournamentPoints ...PlayerTime
      }
      options {
        speed maxSpareTime maxExceedTime
      }
      notificationsChannel notificationsCount mode setup state myPlayerId endDate
      tournament {
        number signedUp
      }
    }
  } ${PLAYERS_TIME} ${AVATAR_INFO}
`

export function RemoteGameLoader({ gameId }: { gameId: string }) {
  const dispatch = useDispatch()
  const [getGame, { data }] = useLazyQuery<{ game: GameData, time: number }>(GET_GAME)
  useEffect(() => {
    getGame({ variables: { id: gameId } }).then(result => {
      if (result.data) {
        dispatch(gameStateLoaded(result.data.game, result.data.time))
      }
    })
  }, [])
  if (!data) return null
  else return <GameNotificationsListener channelName={data.game.notificationsChannel ?? `game=${gameId}`} gameId={gameId}/>
}