import { CardDescription } from '@gamepark/react-game'
import monsterBack from '../images/monster-back.jpg'
import monster23 from '../images/en/monster-2-3.jpg'
import monster24 from '../images/en/monster-2-4.jpg'
import monster34 from '../images/en/monster-3-4.jpg'
import monster35 from '../images/en/monster-3-5.jpg'
import monster42 from '../images/en/monster-4-2.jpg'
import monster2Alchemy from '../images/en/monster-2-alchemy.jpg'
import monster3Defense from '../images/en/monster-3-defense.jpg'
import monster4Combat from '../images/en/monster-4-combat.jpg'
import monster4Specialty from '../images/en/monster-4-specialty.jpg'
import monsterDiscard3 from '../images/en/monster-discard-3.jpg'
import monsterDiscard4 from '../images/en/monster-discard-4.jpg'
import monsterTrashCombat from '../images/en/monster-trash-combat.jpg'
import monsterTrash2 from '../images/en/monster-trash-2.jpg'
import monsterTrash3 from '../images/en/monster-trash-3.jpg'
import monsterLevelDefense from '../images/en/monster-level-defense.jpg'
import monsterDiscardLevel from '../images/en/monster-discard-level.jpg'
import monster5Alchemy from '../images/en/monster-5-alchemy.jpg'
import monster52 from '../images/en/monster-5-2.jpg'

export default class MonsterCardDescription extends CardDescription {
  width = 6.5
  height = 10

  rules = () => <></>

  backImage = monsterBack

  images = {
    [1]: monster35,
    [2]: monsterDiscard4,
    [3]: monster23,
    [4]: monster24,
    [5]: monster34,
    [6]: monster42,
    [7]: monster2Alchemy,
    [8]: monster3Defense,
    [9]: monster4Combat,
    [10]: monster4Specialty,
    [11]: monsterDiscard3,
    [12]: monsterTrashCombat,
    [13]: monsterTrash2,
    [14]: monsterTrash3,
    [15]: monsterLevelDefense,
    [16]: monsterDiscardLevel,
    [17]: monster5Alchemy,
    [18]: monster52
  }
}