import { Store } from 'redux'
import { ANIMATION_START, animationComplete } from '../Actions'
import { GamePageState, isActionToAnimate } from '../Store'

export function createAnimationsListener(store: Store<GamePageState>) {
  const timers = new Map<number, NodeJS.Timeout>()
  return () => {
    const state = store.getState()
    if (!state.actions) return
    for (const action of state.actions) {
      const animation = action.animation
      if (animation && !timers.has(animation.id)) {
        timers.set(animation.id, setTimeout(() => {
          timers.delete(animation.id)
          store.dispatch(animationComplete(animation.id))
        }, animation.duration * 1000))
      }
    }
    if (timers.size === 0 && !state.animationPaused && state.actions.some(isActionToAnimate)) {
      store.dispatch({ type: ANIMATION_START })
    }
  }
}
