/** @jsxImportSource @emotion/react */
import { PlayMoveButton, useLegalMove, useRules } from '@gamepark/react-game'
import { MonsterDeckRules } from '@gamepark/the-witcher-old-world-monster/MonsterDeckRules'
import { MaterialType } from '@gamepark/the-witcher-old-world-monster/material/MaterialType'
import { LocationType } from '@gamepark/the-witcher-old-world-monster/material/LocationType'
import { Trans } from 'react-i18next'
import { isCustomMove } from '@gamepark/rules-api'

export const MonsterTurnHeader = () => {
  const rules = useRules<MonsterDeckRules>()!
  const discard = rules.material(MaterialType.MonsterCard).location(LocationType.Discard).length
  const shuffle = useLegalMove(isCustomMove)
  return <Trans defaults="header.text" values={{ discard }}><PlayMoveButton move={shuffle}/></Trans>
}
