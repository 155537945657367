import { HiddenMaterialRules, hideItemId, PositiveSequenceStrategy } from '@gamepark/rules-api'
import { MaterialType } from './material/MaterialType'
import { LocationType } from './material/LocationType'
import { RuleId } from './rules/RuleId'
import { MonsterTurn } from './rules/MonsterTurn'


export class MonsterDeckRules extends HiddenMaterialRules<number, MaterialType, LocationType> {
  locationsStrategies = {
    [MaterialType.MonsterCard]: {
      [LocationType.Deck]: new PositiveSequenceStrategy(),
      [LocationType.Discard]: new PositiveSequenceStrategy()
    }
  }

  hidingStrategies = {
    [MaterialType.MonsterCard]: {
      [LocationType.Deck]: hideItemId
    }
  }

  rules = {
    [RuleId.MonsterTurn]: MonsterTurn
  }

  moveBlocksUndo() {
    return false
  }
}