import { gql, useApolloClient } from '@apollo/client'
import { useChannel, useEvent } from '@harelpls/use-pusher'
import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { gameNotificationsReceived, gameOver } from '../../Actions'
import { GameData, GameMessage, GameNotification } from '../../Types'
import { NOTIFICATIONS, PLAYERS_TIME } from './GameClientAPI'

const GET_NOTIFICATIONS = gql`
  query GameNotifications($gameId: String!, $start: Int) {
    time
    gameNotifications(gameId: $gameId, start: $start) { ...Notifications }
    game(id: $gameId) {
      players { id ...PlayerTime }
    }
  } ${NOTIFICATIONS} ${PLAYERS_TIME}
`

export function GameNotificationsListener({ gameId, channelName }: { gameId: string, channelName: string }) {
  const channel = useChannel(channelName)
  const client = useApolloClient()
  const dispatch = useDispatch()
  const notification = useRef(0)

  useEvent(channel, 'pusher:subscription_error', error => console.error('pusher subscription failed', error))

  useEvent(channel, 'pusher:subscription_succeeded', async () => {
    const start = notification.current
    const { data } = await client.query<{ gameNotifications: GameNotification[], game: GameData, time: number }>({
      query: GET_NOTIFICATIONS,
      variables: { gameId, start }
    })
    if (!data) return console.error('Failed to collect game data')
    dispatch!(gameNotificationsReceived(data.gameNotifications, start, data.game.players, data.time))
    if (data.gameNotifications.length > 0) {
      notification.current = Math.max(notification.current, start + data.gameNotifications.length)
    }
  })

  useEvent<GameMessage>(channel, 'moves-played', (message) => {
    if (!message) return
    const { index, notifications, players, date } = message
    dispatch!(gameNotificationsReceived(notifications, index, players, Date.parse(date)))
    notification.current = Math.max(notification.current, index + notifications.length)
  })

  useEvent<{ id: any, gamePointsDelta?: number, tournamentPoints?: number }[]>(channel, 'game-over', (players) => {
    if (players) {
      dispatch!(gameOver(players))
    }
  })

  return null
}