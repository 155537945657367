import { DeckLocator, LocationDescription } from '@gamepark/react-game'
import { LocationType } from '@gamepark/the-witcher-old-world-monster/material/LocationType'

export class MonsterDiscardLocator extends DeckLocator {
  coordinates = { x: 4, y: 0, z: 0 }
  delta = { x: -0.04, y: -0.04, z: 0.1 }
  locationDescription = new MonsterDiscardDescription()
}

class MonsterDiscardDescription extends LocationDescription {
  location = { type: LocationType.Discard }
  coordinates = { x: 4, y: 0, z: 10 }
  width = 7
  height = 10.5
  borderRadius = 0.4
}
