import { gql, useQuery } from '@apollo/client'
import { useState } from 'react'
import { ChatChannel, Message } from '../Chat'
import { useChannel, useEvent } from '@harelpls/use-pusher'

const GET_CHAT_MESSAGES = gql`query ChatMessages($channel: String!, $limit: Int, $maxDate: DateTime) {
  chatChannel(name: $channel) {
    name
    messages(limit: $limit, maxDate: $maxDate) {
      id userId text date
    }
  }
}`

export function useChatMessages(gameId: string, onMessageReceived?: (message: Message) => void) {
  const channel = useChannel(`game=${gameId}`)
  const [subscribed, setSubscribed] = useState(false)
  const [hasMoreMessages, setHasMoreMessages] = useState(false)
  const { data, loading, fetchMore, client } = useQuery(GET_CHAT_MESSAGES, {
    variables: { channel: `game=${gameId}` }, skip: !subscribed,
    onCompleted: ({ chatChannel: { messages } }: { chatChannel: ChatChannel }) => setHasMoreMessages(messages.length === 10)
  })
  const messages = data?.chatChannel.messages ?? []
  useEvent(channel, 'pusher:subscription_succeeded', () => setSubscribed(true))
  useEvent<Message>(channel, 'message', message => {
    if (message) {
      client.writeFragment({
        id: `ChatChannel:{"name":"game=${gameId}"}`,
        fragment: gql`fragment ChatMessages on ChatChannel { messages {  id userId text date } }`,
        data: { messages: [{ __typename: 'Message', ...message }] }
      })
      if (onMessageReceived) onMessageReceived(message)
    }
  })
  return { messages, hasMoreMessages, loading, fetchMore }
}