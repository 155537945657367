import {
  Action,
  ActionView,
  applyAutomaticMoves,
  DefaultGameSetup,
  Dummy,
  GameSetup,
  GameSpeed,
  generatePlayersOption,
  getGameView,
  getMoveView,
  hasIncompleteInformation,
  hasSecretInformation,
  hasUndo,
  isSecretAction,
  isWithPlayerIdOptions,
  isWithPlayerOptions,
  OptionsSpec,
  playAction,
  playActionWithViews,
  replayActions,
  Robot,
  RulesCreator,
  SecretAction
} from '@gamepark/rules-api'
import assert from 'assert'
import { Dispatch } from 'redux'
import { gameNotificationsReceived, gameOver, gameStateLoaded, setAnimationsSpeed } from '../../Actions'
import { TutorialDescription } from '../../Tutorial'
import {
  GameAI,
  GameData,
  GameMode,
  GameNotification,
  movePlayedNotification,
  MovePlayedNotification,
  moveUndoneNotification,
  Player,
  ProxyGameData
} from '../../Types'
import { findLastIndex } from '../../util/ArrayUtil'
import { GameAPI } from '../GameAPI'
import { LocalGameProviderProps } from './LocalGameProvider'

export class GameLocalAPI<Game, Move = string, PlayerId = number, GameView = Game, MoveView = Move, Options extends { players: any } | undefined = undefined> implements GameAPI<Move, PlayerId> {
  game: string
  gameSetup: GameSetup<Game, Options>
  Rules: RulesCreator<Game, Move, PlayerId, Options>
  ClientRules: RulesCreator<GameView, MoveView, PlayerId, Options>
  dummy: Robot<Game, Move, PlayerId>
  optionsSpec?: OptionsSpec<Options>
  private readonly ai: GameAI<Game, Move, PlayerId>
  private readonly tutorial?: TutorialDescription<Game, Move, PlayerId>
  dispatch?: Dispatch
  private lastActionId: number = 0
  private notificationsCount: number = 0
  private autoPlayersCounter: number = 0
  private aiProcessing: boolean = false
  private tutorialMovesToPlay: number = 0

  constructor({ Rules, ServerRules, GameSetup, ai, dummy, game, optionsSpec, tutorial }: LocalGameProviderProps<Game, GameView, Move, MoveView, PlayerId>) {
    this.game = game
    this.Rules = ServerRules ?? Rules as RulesCreator<Game, Move, PlayerId, Options> & RulesCreator<GameView, MoveView, PlayerId, Options>
    this.gameSetup = GameSetup ? new GameSetup() : new DefaultGameSetup(this.Rules)
    this.ClientRules = Rules as RulesCreator<Game, Move, PlayerId, Options> & RulesCreator<GameView, MoveView, PlayerId, Options>
    this.dummy = dummy ?? this.createDummy(this.Rules)
    this.optionsSpec = optionsSpec
    this.ai = ai || this.dummy
    this.tutorial = tutorial
  }

  createDummy<Game, Move, PlayerId>(Rules: RulesCreator<Game, Move, PlayerId>): Robot<Game, Move, PlayerId> {
    const dummy = new Dummy(Rules)
    return (state: Game, playerId: PlayerId) => dummy.getRandomMove(state, playerId)
  }

  init(dispatch: Dispatch) {
    this.dispatch = dispatch
    const animationsSpeed = parseFloat(localStorage.getItem('animationsSpeed') || '1')
    this.dispatch(setAnimationsSpeed(animationsSpeed))
    const data = this.initLocalStore()
    const visibleActions = data.actions.filter(action => !isSecretAction(action) || !action.secrets.some(secret => secret.recipient === data.playerId))
    this.notificationsCount = visibleActions.length
    const dataToTransfer: GameData<Game | GameView, PlayerId> = {
      myPlayerId: data.playerId, setup: data.setup, state: data.state, notificationsCount: this.notificationsCount,
      players: data.players, options: data.options ?? { speed: GameSpeed.Disabled, maxSpareTime: 0, maxExceedTime: 0 },
      mode: data.tutorial ? GameMode.TUTORIAL : GameMode.FRIENDLY, notificationsChannel: '', endDate: null, tournament: null
    }
    const initialGame = new this.Rules(JSON.parse(JSON.stringify(data.setup)))
    if (hasIncompleteInformation<GameView, Move, MoveView>(initialGame)) {
      dataToTransfer.setup = verifyNonMutation(() => getGameView<Game, GameView, PlayerId>(initialGame, data.playerId), () => initialGame.game, 'getView must not change the game state')
      if (visibleActions.length < data.actions.length) {
        dataToTransfer.setup = JSON.parse(JSON.stringify(dataToTransfer.setup))
        replayActions(initialGame, visibleActions)
        dataToTransfer.state = verifyNonMutation(() => getGameView<Game, GameView, PlayerId>(initialGame, data.playerId), () => initialGame.game, 'getView must not change the game state')
      } else {
        const rules = new this.Rules(data.state)
        if (hasIncompleteInformation<GameView, Move, MoveView>(rules)) {
          dataToTransfer.state = verifyNonMutation(() => getGameView<Game, GameView, PlayerId>(rules, data.playerId), () => rules.game, 'getView must not change the game state')
        }
      }
    }
    mimicDataTransfer(dataToTransfer).then(gameData => {
      dispatch(gameStateLoaded(gameData))
      mimicDataTransfer(this.getNotifications(data)).then(notifications => dispatch(gameNotificationsReceived(notifications, 0, data.players)))
    })
  }

  initLocalStore() {
    const localStore = localStorage.getItem(this.game)
    if (localStore) {
      const data: ProxyGameData<Game, MoveView, PlayerId> = JSON.parse(localStore)
      if (process.env.NODE_ENV !== 'development' && this.tutorial && !data.tutorial) {
        return this.newTutorial()
      }
      if (this.tutorial?.version && data.tutorial && data.tutorial !== this.tutorial.version) {
        return this.newTutorial()
      }
      if (data.actions.length > 0 && !data.actions[0].id) {
        data.actions.forEach((action, index) => {
          if (!action.id) action.id = index.toString()
        })
        localStorage.setItem(this.game, JSON.stringify(data))
      }
      if (data.actions.length > 0) {
        const lastActionId = parseInt(data.actions[data.actions.length - 1].id!)
        if (!isNaN(lastActionId)) {
          this.lastActionId = lastActionId
        }
      }
      return data
    } else {
      return this.tutorial ? this.newTutorial() : this.newGame()
    }
  }

  getNotifications(data: ProxyGameData<Game, MoveView, PlayerId>): GameNotification<PlayerId>[] {
    const rules = new this.Rules(data.setup)
    const actions = data.actions.filter(action => !isSecretAction(action) || !action.secrets.some(secret => secret.recipient === data.playerId))
    if (hasIncompleteInformation(rules)) {
      const clientGame = new this.ClientRules(JSON.parse(JSON.stringify(getGameView(rules, data.playerId))), { player: data.playerId })
      const notifications: GameNotification<PlayerId>[] = []
      for (const action of actions) {
        const moveView = verifyNonMutation(() => getMoveView(rules, JSON.parse(JSON.stringify(action.move)), data.playerId),
          () => rules.game, 'getMoveView must not change the state of the game')
        clientGame.play(JSON.parse(JSON.stringify(moveView)))
        rules.play(JSON.parse(JSON.stringify(action.move)))
        const consequences: any[] = []
        for (const move of action.consequences) {
          const moveView = verifyNonMutation(() => getMoveView(rules, JSON.parse(JSON.stringify(move)), data.playerId),
            () => rules.game, 'getMoveView must not change the state of the game')
          consequences.push(moveView)
          clientGame.play(JSON.parse(JSON.stringify(moveView)))
          rules.play(JSON.parse(JSON.stringify(move)))
        }
        notifications.push(movePlayedNotification({ ...action, move: moveView, consequences }))
      }
      const serverGameView = JSON.parse(JSON.stringify(getGameView(rules, data.playerId)), (_, value) => Array.isArray(value) ? new Set(value) : value)
      const clientGameView = JSON.parse(JSON.stringify(clientGame.game), (_, value) => Array.isArray(value) ? new Set(value) : value)
      try {
        assert.deepStrictEqual(serverGameView, clientGameView)
      } catch (e) {
        console.error('Something is wrong with rules.getView or rules.getMoveView implementation: the view is not the same when moveViews are applied')
        console.error('Game view when moves are applied then getView is executed:', serverGameView)
        console.error('Game view when moves views are applied from the setup state:', clientGameView)
        console.error('If not fixed, there can be bugs especially with the UNDO features.')
      }
      return notifications
    } else {
      return actions.map(action => movePlayedNotification(action))
    }
  }

  newTutorial(): ProxyGameData {
    if (!this.tutorial) {
      throw new Error('Pass a Tutorial in the options of createGameStore first')
    }
    const [state, players] = this.tutorial.setupTutorial()
    return this.initGameStore(state, players, this.tutorial.version ?? 1) // TODO: tutorial extends Rules?
  }

  private getGameOptions(arg: Options | number = 2): Options {
    const options: any = typeof arg === 'object' ? arg : { players: typeof arg === 'number' ? arg : 2 }
    if (typeof options.players !== 'number' && !Array.isArray(options.players)) options.players = 2
    if (this.optionsSpec && isWithPlayerOptions(this.optionsSpec)) {
      if (typeof options.players === 'number') {
        options.players = [...Array(options.players)].map(() => ({}))
      } else {
        for (let i = 0; i < options.players.length; i++) {
          if (typeof options.players[i] !== 'object') options.players[i] = {}
        }
      }
      for (const option in this.optionsSpec.players) {
        const optionValues = generatePlayersOption(options.players.map((p: any) => p[option] ?? null), this.optionsSpec.players[option])
        for (let i = 0; i < options.players.length; i++) {
          options.players[i][option] = optionValues[i]
        }
      }
    }
    return options
  }

  newGame(arg: Options | number = 2): ProxyGameData {
    const options = this.getGameOptions(arg)!
    const playerIds: PlayerId[] = this.optionsSpec && isWithPlayerIdOptions(this.optionsSpec) ?
      options.players.map((player: any) => player.id)
      : (Array.isArray(options.players) ? options.players : [...Array(options.players)]).map((_, index) => index + 1)
    const players = playerIds.map<Player<PlayerId>>(id => ({ id }))
    return this.initGameStore(this.gameSetup.setup(options), players)
  }

  initGameStore(game: Game, players: Player<PlayerId>[], tutorial?: number): ProxyGameData<Game, Move, PlayerId> {
    const rules = new this.Rules(game)
    const playerId = players[0].id
    applyAutomaticMoves(rules)
    const setup = JSON.parse(JSON.stringify(rules.game))
    const localStore: ProxyGameData<Game, Move, PlayerId> = { players, setup, state: rules.game, actions: [], playerId, monkeyOpponents: !!tutorial, tutorial }
    localStorage.setItem(this.game, JSON.stringify(localStore))
    return localStore
  }

  getLocalStore() {
    return JSON.parse(localStorage.getItem(this.game)!) as ProxyGameData<Game, Move, PlayerId>
  }

  play(move: Move) {
    return mimicDataTransfer(move).then(move => {
      const localStore = this.getLocalStore()
      const rules = new this.Rules(localStore.state) // TODO: this.tutorial?
      if (!verifyNonMutation(() => rules.isLegalMove(localStore.playerId!, move), () => rules.game, 'isLegalMove and getLegalMoves must not change the game state')) {
        console.error('This move is not authorized right now: ' + JSON.stringify(move))
        if (rules.getLegalMoves) {
          console.info('Legal moves are:', rules.getLegalMoves(localStore.playerId!))
        }
        return
      }
      this.autoPlayersCounter = 0
      this.playActions(localStore.playerId!, [move])
    }).catch(error => {
      console.error('The move could not be applied due to this error:', error)
      throw error
    })
  }

  playActions(playerId: PlayerId, moves: Move[]) {
    const localStore = this.getLocalStore()
    const game = new this.Rules(localStore.state) // TODO: this.tutorial?
    const actions: Action<Move, PlayerId>[] = []
    const notifications: MovePlayedNotification<any, PlayerId>[] = []
    const id = (++this.lastActionId).toString()
    if (hasIncompleteInformation(game)) {
      for (const move of moves) {
        const recipients = hasSecretInformation(game) && game.getPlayerMoveView ? localStore.players.map(player => player.id) : []
        const { action, views } = playActionWithViews(game, move, playerId, recipients, id)
        if (hasSecretInformation(game) && game.keepMoveSecret) {
          // reveal moves no longer secret
          for (const secretAction of localStore.actions.filter<SecretAction<Move, any, PlayerId>>(isSecretAction)) {
            const secretToPlayer = secretAction.secrets.find(secret => secret.recipient === localStore.playerId)
            if (secretToPlayer && !game.keepMoveSecret(secretAction.move, localStore.playerId)) {
              notifications.push(movePlayedNotification(secretToPlayer.action))
            }
            secretAction.secrets = secretAction.secrets.filter(secret => secret.recipient === undefined || game.keepMoveSecret!(secretAction.move, secret.recipient))
            if (secretAction.secrets.length === 1) { // only spectator remains => no longer secret
              delete (secretAction as any).secrets
            }
          }

          // Add new secret move if needed
          const secrets: ActionView<any, PlayerId>[] = []
          for (const player of localStore.players) {
            const view = views.find(view => view.recipient === player.id) ?? views.find(view => !view.recipient)!
            if (player.id !== playerId && game.keepMoveSecret(move, player.id)) {
              secrets.push({ recipient: player.id, action: view.action })
            } else if (player.id === localStore.playerId) {
              notifications.push(movePlayedNotification(view.action)) // TODO: MovePlayedNotification = Action
            }
          }
          if (secrets.length) {
            secrets.push(views.find(view => view.recipient === undefined)!);
            (action as SecretAction<Move, any, PlayerId>).secrets = secrets
          }
        } else {
          const view = views.find(view => view.recipient === localStore.playerId) ?? views.find(view => !view.recipient)!
          notifications.push(movePlayedNotification(view.action)) // TODO: MovePlayedNotification = Action
        }
        actions.push(action)
      }
    } else {
      for (const move of moves) {
        const action = playAction(game, move, playerId)
        action.id = id
        actions.push(action)
        notifications.push(movePlayedNotification(action))
      }
    }
    mimicDataTransfer({ notifications, index: this.incrementNotificationsCount(notifications.length) }).then(data =>
      this.dispatch!(gameNotificationsReceived(data.notifications, data.index, localStore.players))
    )
    localStore.actions.push(...actions)
    localStorage.setItem(this.game, JSON.stringify(localStore))
    setTimeout(() => this.runAutoPlayers(), 100 + Math.random() * 100)
  }

  private incrementNotificationsCount(quantity = 1) {
    const previousValue = this.notificationsCount
    this.notificationsCount += quantity
    return previousValue
  }

  undo(arg: string | number = 1, ignoreRules?: boolean) {
    if (typeof arg === 'string') {
      mimicDataTransfer(arg).then(actionId => {
        const localStore = this.getLocalStore()
        const index = findLastIndex(localStore.actions, action => action.id === actionId)
        const action = localStore.actions[index]
        if (!ignoreRules) {
          const game = new this.Rules(localStore.state)
          if (!hasUndo(game)) return console.error('This game does not have UNDO feature')
          if (!game.canUndo(action, localStore.actions.slice(index + 1))) return console.error('Trying to UNDO a move while it is forbidden')
        }
        localStore.actions.splice(index, 1)
        const rules = new this.Rules(JSON.parse(JSON.stringify(localStore.setup)))
        replayActions(rules, localStore.actions)
        localStore.state = rules.game
        localStorage.setItem(this.game, JSON.stringify(localStore))
        if (!isSecretAction(action) || !action.secrets.some(secret => secret.recipient === localStore.playerId)) {
          mimicDataTransfer({ notification: moveUndoneNotification(action, index), index: this.incrementNotificationsCount() }).then(data =>
            this.dispatch!(gameNotificationsReceived([data.notification], data.index, localStore.players))
          )
        }
      })
    } else if (arg > 0) {
      const actions = this.getLocalStore().actions
      if (!actions.length) return console.warn('Cannot undo, no actions were played yet')
      for (let i = actions.length - 1; i > actions.length - 1 - arg; i--) {
        this.undo(actions[i].id, true)
      }
    }
  }

  startMonkeyOpponents() {
    const localStore = this.getLocalStore()
    localStore.monkeyOpponents = true
    localStorage.setItem(this.game, JSON.stringify(localStore))
    this.autoPlayersCounter = 0
    this.runAutoPlayers()
  }

  stopMonkeyOpponents() {
    const localStore = this.getLocalStore()
    localStore.monkeyOpponents = false
    localStorage.setItem(this.game, JSON.stringify(localStore))
  }

  private async runAutoPlayers() {
    if (this.autoPlayersCounter > 100) {
      console.warn('The automatic players played 100 times. There might be an infinite loop, stopping now.')
      return
    }
    const localStore = this.getLocalStore()
    const rules = new this.Rules(localStore.state)
    if (rules.isOver(localStore.players.map(player => player.id))) {
      console.info('Game is over')
      this.dispatch?.(gameOver([]))
      return
    }
    if (localStore.tutorial && this.tutorial) {
      if (!this.tutorialMovesToPlay) return
      const nextExpectedMove = this.tutorial.getNextMove(rules, localStore.actions.length)
      if (nextExpectedMove) {
        const expectedMoves = Array.isArray(nextExpectedMove) ? nextExpectedMove : [nextExpectedMove]
        if (expectedMoves.some(expectedMove => rules.isLegalMove(localStore.playerId!, expectedMove))) {
          if (this.tutorialMovesToPlay !== Infinity) {
            this.tutorialMovesToPlay = 0
          }
          return // Player turn
        }
        const expectedMove = expectedMoves[0]
        const player = localStore.players.find(player => rules.isLegalMove(player.id, expectedMove))
        if (player) {
          this.tutorialMovesToPlay--
          return this.playActions(player.id, [expectedMove])
        } else {
          console.error('Tutorial expects a move that no player can play: ', expectedMove)
        }
      }
    }
    if (localStore.monkeyOpponents && !this.aiProcessing) {
      const player = localStore.players.find(player => player.id !== localStore.playerId && rules.isTurnToPlay(player.id))
      if (player) {
        this.aiProcessing = true
        const moves = await this.ai(getGameView(rules, player.id), player.id, localStore.actions)
        this.aiProcessing = false
        if (moves.length) {
          this.playActions(player.id, moves)
          this.autoPlayersCounter++
        } else {
          console.error(`Player ${player.id} is active but did not return any move to play!`)
        }
      } else if (localStore.playerId === undefined || !rules.isTurnToPlay(localStore.playerId)) {
        console.error('Game is not over but there is no active player!')
      }
    }
  }

  setPlayerName(name: string, playerId?: PlayerId) {
    const localStore = this.getLocalStore()
    const player = localStore.players.find(player => player.id === (playerId || localStore.playerId))
    if (!player) {
      throw new Error('Player not found')
    }
    player.name = name
    localStorage.setItem(this.game, JSON.stringify(localStore))
  }

  setAnimationsSpeed(speed = 1) {
    this.dispatch!(setAnimationsSpeed(speed))
    localStorage.setItem('animationsSpeed', speed.toString())
  }

  eject() {
    // Eject feature is not yet available in development mode, and makes no sense in tutorial mode
  }

  giveUp() {
    // Leaving is not yet available in development mode, and makes no sense in tutorial mode
  }

  playTutorialMoves(quantity: number) {
    if (quantity) {
      this.tutorialMovesToPlay += quantity
      this.autoPlayersCounter = 0
      setTimeout(() => this.runAutoPlayers(), 100 + Math.random() * 100)
    } else {
      this.tutorialMovesToPlay = 0
    }
  }
}

function mimicDataTransfer<D>(data?: D, duration = 100): Promise<D> {
  return new Promise(resolve => setTimeout(() => resolve(data ? JSON.parse(JSON.stringify(data)) : undefined), duration))
}

function verifyNonMutation<T>(operation: () => T, getState: () => any, message?: string): T {
  const initialState = JSON.stringify(getState())
  const result = operation()
  const finalState = JSON.stringify(getState())
  assert(initialState === finalState, message)
  return result
}