import { MaterialGame } from './MaterialGame'
import { Material, MaterialMutator } from './items'
import { RuleStep } from './rules'
import { LocationStrategy } from './location'
import { GameSetup } from '../GameSetup'
import { GameMemory, PlayerMemory } from './memory'

export abstract class MaterialGameSetup<P extends number = number, M extends number = number, L extends number = number, Options = any>
  implements GameSetup<MaterialGame<P, M, L>, Options> {

  readonly locationsStrategies: Partial<Record<M, Partial<Record<L, LocationStrategy<P, M, L>>>>> = {}
  readonly materialLocations: Partial<Record<M, L[]>> = {}

  protected game: MaterialGame<P, M, L> = { players: [], items: {}, memory: {} }

  setup(options: Options): MaterialGame<P, M, L> {
    this.game = { players: getPlayerIds(options), items: {}, memory: {} }
    this.setupMaterial(options)
    this.game.rule = this.start(options)
    return this.game
  }

  get players(): P[] {
    return this.game.players
  }

  abstract setupMaterial(options: Options): void

  material(type: M): Material<P, M, L> {
    if (!this.game.items[type]) this.game.items[type] = []
    const items = this.game.items[type]!
    const mutator = new MaterialMutator(type, items, this.locationsStrategies[type], this.materialLocations[type])
    return new Material(type, Array.from(items.entries()).filter(entry => entry[1].quantity !== 0),
      move => mutator.applyMove(mutator.randomize(JSON.parse(JSON.stringify(move)))))
  }

  protected getMemory(player?: P) {
    return player === undefined ? new GameMemory(this.game) : new PlayerMemory(this.game, player)
  }

  memorize<T = any>(key: keyof any, value: T | ((lastValue: T) => T), player?: P): void {
    this.getMemory(player).memorize(key, value)
  }

  abstract start(options: Options): RuleStep<P>
}

function getPlayerIds<Player extends number = number>(options: any): Player[] {
  if (Array.isArray(options.players)) {
    return options.players.map((player: any, index: number) => player.id ?? index + 1)
  } else {
    const numberOfPlayers = options.players ?? 2
    return Array.from(Array(numberOfPlayers).keys()).map(index => (index + 1) as Player)
  }
}
