import { ItemMoveType } from './ItemMoveType'
import { MaterialMove } from '../MaterialMove'
import { MoveKind } from '../MoveKind'
import { MaterialMoveCommon } from './ItemMove'
import { ItemPosition, MaterialItem } from '../../items'

export type MoveItem<Player extends number = number, MaterialType extends number = number, LocationType extends number = number>
  = MaterialMoveCommon<MaterialType> & {
  type: typeof ItemMoveType.Move
  itemIndex: number
  position: Partial<ItemPosition<Player, LocationType>>
  quantity?: number
  reveal?: Omit<MaterialItem<Player, LocationType>, 'location'>
}

export type MoveItemLocation<P extends number, M extends number, L extends number> = MoveItem<P, M, L> & {
  position: ItemPosition<P, L>
}

export function isMoveItem<P extends number, M extends number, L extends number>(move: MaterialMove<P, M, L>): move is MoveItem<P, M, L> {
  return move.kind === MoveKind.ItemMove && move.type === ItemMoveType.Move
}

export function isMoveItemType<P extends number, M extends number, L extends number>(
  type: M, index?: number
): (move: MaterialMove<P, M, L>) => move is MoveItem<P, M, L> {
  return (move: MaterialMove<P, M, L>): move is MoveItem<P, M, L> =>
    isMoveItem(move) && move.itemType === type && (index === undefined || move.itemIndex === index)
}

export function isMoveItemLocation<P extends number, M extends number, L extends number>(move: MaterialMove<P, M, L>): move is MoveItemLocation<P, M, L> {
  return isMoveItem(move) && move.position.location !== undefined
}

export function isMoveItemTypeLocation<P extends number, M extends number, L extends number>(
  type: M, index?: number
): (move: MaterialMove<P, M, L>) => move is MoveItemLocation<P, M, L> {
  return (move: MaterialMove<P, M, L>): move is MoveItemLocation<P, M, L> =>
    isMoveItemLocation(move) && move.itemType === type && (index === undefined || move.itemIndex === index)
}
