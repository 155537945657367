import { Player } from '../Types'

export type PlayerTime = {
  playing: boolean
  lastChange: string
  availableTime: number
  highestPlayTime: number
  highestDownTime: number
  cumulatedPlayTime: number
  cumulatedDownTime: number
  cumulatedWaitForMeTime: number
  weightedWaitForMeTime: number
}

export function getPlayerTimeout(player: Player) {
  if (!player.time || !player.time.playing) return Infinity
  return Date.parse(player.time.lastChange) + player.time.availableTime
}