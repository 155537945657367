import { MaterialMove, MaterialRulesPart } from '@gamepark/rules-api'
import { MaterialType } from '../material/MaterialType'
import { LocationType } from '../material/LocationType'

export class MonsterTurn extends MaterialRulesPart {
  getLegalMoves() {
    const moves: MaterialMove[] = []
    const topCard = this.material(MaterialType.MonsterCard).location(LocationType.Deck).sort(item => -item.location.x!).limit(1)
    if (topCard.length) {
      moves.push(topCard.moveItem({ location: { type: LocationType.Discard } }))
    }
    if (this.material(MaterialType.MonsterCard).location(LocationType.Discard).length) {
      moves.push(this.rules().customMove(0))
    }
    return moves
  }

  onCustomMove() {
    return [
      ...this.material(MaterialType.MonsterCard).location(LocationType.Discard).moveItems({ location: { type: LocationType.Deck } }),
      this.material(MaterialType.MonsterCard).shuffle()
    ]
  }

  isTurnToPlay(): boolean {
    return true
  }
}