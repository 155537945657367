import { ApolloError, gql } from '@apollo/client'
import { Dispatch } from 'redux'
import { actionFailed, gameNotificationsReceived } from '../../Actions'
import { GameMessage } from '../../Types'
import { GameAPI } from '../GameAPI'
import { getApolloClient } from './apollo-client'
import { Failure } from '../../Failure'

export const AVATAR_INFO = gql`fragment AvatarInfo on Avataaar {accessoriesType clotheColor clotheType eyeType eyebrowType facialHairType facialHairColor graphicType hairColor mouthType skinColor topType}`
export const PLAYERS_TIME = gql`fragment PlayerTime on Player { time { availableTime cumulatedDownTime cumulatedPlayTime cumulatedWaitForMeTime highestDownTime highestPlayTime lastChange playing weightedWaitForMeTime } }`
export const NOTIFICATIONS = gql`fragment Notifications on GameNotification { playerId ... on MovePlayedNotification { actionId moveView consequences } ... on MoveUndoneNotification { actionId moveUndone } }`
const PLAY = gql`mutation PlayMoves($gameId: String!, $moves: [JSON!]!) { playMoves(gameId: $gameId, moves: $moves) { notifications { ...Notifications } index players { id ...PlayerTime } date } } ${NOTIFICATIONS} ${PLAYERS_TIME}`
const UNDO = gql`mutation UndoMoves($gameId: String!, $actions: [String!]!) { undoMoves(gameId: $gameId, actions: $actions) { notifications { ...Notifications } index players { id ...PlayerTime } date } } ${NOTIFICATIONS} ${PLAYERS_TIME}`
const EJECT_PLAYER = gql`mutation EjectPlayer($gameId: String!, $playerId: JSON!) { ejectPlayer(gameId: $gameId, playerId: $playerId) { notifications { ...Notifications } index players { id ...PlayerTime } date } } ${NOTIFICATIONS} ${PLAYERS_TIME}`
const GIVE_UP = gql`mutation GiveUp($gameId: String!) { giveUp(gameId: $gameId) { notifications { ...Notifications } index players { id ...PlayerTime } date } } ${NOTIFICATIONS} ${PLAYERS_TIME}`
export const GET_MESSAGE_AUTHOR = gql`
  query GetMessageAuthor($id: String!) {
    user(id: $id) {
      id name avatar {...AvatarInfo}
    }
  } ${AVATAR_INFO}
`

export class GameClientAPI<Move = any, PlayerId = any> implements GameAPI<Move, PlayerId> {
  private dispatch?: Dispatch
  private readonly gameId: string
  private ongoingAction?: Promise<any>
  private pendingActions: PendingAction<Move>[] = []

  constructor(gameId: string) {
    this.gameId = gameId
  }

  async init(dispatch: Dispatch) {
    this.dispatch = dispatch
  }

  play(move: Move) {
    if (this.pendingActions.length === 0) {
      this.pendingActions.push({ type: 'PLAY', moves: [move] })
    } else {
      const lastPendingAction = this.pendingActions[this.pendingActions.length - 1]
      if (lastPendingAction.type === 'PLAY') {
        lastPendingAction.moves.push(move)
      } else {
        this.pendingActions.push({ type: 'PLAY', moves: [move] })
      }
    }
    if (!this.ongoingAction) {
      // Wait for 50ms before sending this move: maybe more moves are coming very soon
      this.ongoingAction = new Promise(resolve => setTimeout(resolve, 50)).then(() => this.executePendingActions())
    }
  }

  undo(action: number | string) {
    if (typeof action === 'number') return console.error('GameClientAPI can no longer undo without an action ID')
    if (this.pendingActions.length === 0) {
      this.pendingActions.push({ type: 'UNDO', actions: [action] })
    } else {
      const lastPendingAction = this.pendingActions[this.pendingActions.length - 1]
      if (lastPendingAction.type === 'UNDO') {
        lastPendingAction.actions.push(action)
      } else {
        this.pendingActions.push({ type: 'UNDO', actions: [action] })
      }
    }
    if (!this.ongoingAction) {
      this.executePendingActions()
    }
  }

  private executePendingActions() {
    if (this.pendingActions.length > 0) {
      const pendingAction = this.pendingActions.shift()!
      if (pendingAction.type == 'PLAY') {
        this.ongoingAction = getApolloClient().mutate<{ playMoves: GameMessage<PlayerId> }>({
          mutation: PLAY, variables: { gameId: this.gameId, moves: pendingAction.moves }
        }).then(result => result.data && this.handleGameMessageResult(result.data.playMoves))
      } else if (pendingAction.type === 'UNDO') {
        this.ongoingAction = getApolloClient().mutate<{ undoMoves: GameMessage<PlayerId> }>({
          mutation: UNDO, variables: { gameId: this.gameId, actions: pendingAction.actions }
        }).then(result => result.data && this.handleGameMessageResult(result.data.undoMoves))
      }
      if (this.ongoingAction) {
        this.ongoingAction = this.ongoingAction.catch(error => this.onError(error)).finally(() => this.executePendingActions())
      }
    } else {
      delete this.ongoingAction
    }
  }

  private handleGameMessageResult({ date, index, notifications, players }: GameMessage<PlayerId>) {
    this.dispatch!(gameNotificationsReceived(notifications, index, players, Date.parse(date)))
  }

  eject(playerId: PlayerId) {
    getApolloClient().mutate<{ ejectPlayer: GameMessage<PlayerId> }>({ mutation: EJECT_PLAYER, variables: { gameId: this.gameId, playerId } })
      .then(result => result.data && this.handleGameMessageResult(result.data.ejectPlayer))
  }

  giveUp() {
    getApolloClient().mutate<{ giveUp: GameMessage<PlayerId> }>({ mutation: GIVE_UP, variables: { gameId: this.gameId } })
      .then(result => result.data && this.handleGameMessageResult(result.data.giveUp))
  }

  onError({ graphQLErrors, networkError }: ApolloError) {
    if (graphQLErrors) {
      graphQLErrors.map(({ message }) => this.dispatch!(actionFailed(message)))
    }
    if (networkError) {
      console.error(networkError)
      this.dispatch!(actionFailed(Failure.NETWORK))
    }
  }

  playTutorialMoves() {
    console.error('Tutorial should only be played with local API')
  }
}

type PendingAction<Move> = PendingMoves<Move> | PendingUndo

type PendingMoves<Move> = {
  type: 'PLAY'
  moves: Move[]
}

type PendingUndo = {
  type: 'UNDO'
  actions: string[]
}
