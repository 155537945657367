/** @jsxImportSource @emotion/react */
import { MonsterDeckRules } from '@gamepark/the-witcher-old-world-monster/MonsterDeckRules'
import { MonsterDeckSetup } from '@gamepark/the-witcher-old-world-monster/MonsterDeckSetup'
import { GameProvider, MaterialGameAnimations, setupTranslation } from '@gamepark/react-game'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import translations from './translations.json'
import { Material } from './material/Material'
import { Locators } from './locators/Locators'
import { ItemMove, ItemMoveType } from '@gamepark/rules-api'
import { LocationType } from '@gamepark/the-witcher-old-world-monster/material/LocationType'

setupTranslation(translations, { debug: false })

class MonsterDeckAnimations extends MaterialGameAnimations {
  getDuration(move: ItemMove) {
    switch (move.type) {
      case ItemMoveType.Move:
        return move.position.location?.type === LocationType.Discard ? 0.2 : 0.05
    }
    return 0
  }
}

ReactDOM.render(
  <StrictMode>
    <GameProvider game="the-witcher-old-world-monster" Rules={MonsterDeckRules} GameSetup={MonsterDeckSetup}
                  material={Material} locators={Locators} animations={new MonsterDeckAnimations()}>
      <App/>
    </GameProvider>
  </StrictMode>,
  document.getElementById('root')
)
