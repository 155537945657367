import { Rules } from './Rules'
import { MaterialGame, MaterialRules } from './material'

export interface Competitive<Game, Move = string, PlayerId = number> extends Rules<Game, Move, PlayerId> {
  rankPlayers(playerA: PlayerId, playerB: PlayerId): number
}

export interface CompetitiveScore<Game, Move = string, PlayerId = number> extends Competitive<Game, Move, PlayerId> {
  getScore(playerId: PlayerId, tieBreaker?: number): number | undefined
}

export function isCompetitive<Game, Move, PlayerId>(rules: Rules<any, any, PlayerId>): rules is Competitive<Game, Move, PlayerId> {
  return (rules as Competitive<Game, Move, PlayerId>).rankPlayers !== undefined
}

export function isCompetitiveScore<Game, Move, PlayerId>(rules: Rules<any, any, PlayerId>): rules is CompetitiveScore<Game, Move, PlayerId> {
  return isCompetitive(rules) && (rules as CompetitiveScore<Game, Move, PlayerId>).rankPlayers !== undefined
}

export const rankByScore = <PlayerId = number>(
  playerA: PlayerId, playerB: PlayerId, getScore: (playerId: PlayerId, tieBreaker?: number) => number | undefined
) => {
  let result: number | undefined = undefined
  let tieBreaker = 0
  while (result === undefined) {
    const scoreA = getScore(playerA, tieBreaker)
    const scoreB = getScore(playerB, tieBreaker)
    if (scoreA === undefined || scoreB === undefined) {
      result = 0
    } else if (scoreA !== scoreB) {
      result = scoreB - scoreA
    } else {
      tieBreaker++
    }
  }
  return result
}

export const getWinners = <PlayerId extends number = number>(
  rules: MaterialRules<PlayerId> & CompetitiveScore<MaterialGame<PlayerId>, any, PlayerId>
): PlayerId[] => {
  let playersLeft = rules.players
  let tieBreaker = 0
  while (playersLeft.length > 1) {
    const scores = playersLeft.map(player => rules.getScore(player, tieBreaker))
    if (scores.some(score => score === undefined)) return playersLeft
    const maxScore = Math.max(...scores as number[])
    playersLeft = playersLeft.filter((_, index) => scores[index] === maxScore)
    tieBreaker++
  }
  return playersLeft
}
