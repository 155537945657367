import { MaterialGameSetup, PositiveSequenceStrategy } from '@gamepark/rules-api'
import { MaterialType } from './material/MaterialType'
import { LocationType } from './material/LocationType'
import { RuleId } from './rules/RuleId'
import range from 'lodash/range'

export class MonsterDeckSetup extends MaterialGameSetup<number, MaterialType, LocationType> {
  locationsStrategies = {
    [MaterialType.MonsterCard]: {
      [LocationType.Deck]: new PositiveSequenceStrategy()
    }
  }

  setupMaterial() {
    this.material(MaterialType.MonsterCard).createItems(range(1, 3).map(id => ({ id, location: { type: LocationType.Deck } })))
    this.material(MaterialType.MonsterCard).createItems(range(1, 19).map(id => ({ id, location: { type: LocationType.Deck } })))
    this.material(MaterialType.MonsterCard).shuffle()
  }

  start() {
    return { id: RuleId.MonsterTurn }
  }
}