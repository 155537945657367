import { Material, MaterialItem } from './index'
import equal from 'fast-deep-equal'
import { LocationStrategy } from '../location'
import {
  CreateItem,
  DeleteItem,
  isShuffleRandomized,
  ItemMove,
  ItemMoveRandomized,
  ItemMoveType,
  ItemMoveView,
  MoveItem,
  Shuffle,
  ShuffleRandomized
} from '../moves'
import merge from 'lodash/merge'
import shuffle from 'lodash/shuffle'

export class MaterialMutator<P extends number = number, M extends number = number, L extends number = number> {
  constructor(
    private readonly type: M,
    private readonly items: MaterialItem<P, L>[],
    private readonly locationsStrategies: Partial<Record<L, LocationStrategy<P, M, L>>> = {},
    private readonly childrenLocations: L[] = []
  ) {
  }

  randomize(move: ItemMove<P, M, L>): ItemMoveRandomized<P, M, L> {
    switch (move.type) {
      case ItemMoveType.Shuffle:
        return { ...move, newIndexes: shuffle(move.indexes) }
      default:
        return move
    }
  }

  applyMove(move: ItemMoveRandomized<P, M, L> | ItemMoveView<P, M, L>): void {
    switch (move.type) {
      case ItemMoveType.Create:
        this.create(move)
        break
      case ItemMoveType.Move:
        this.move(move)
        break
      case ItemMoveType.Delete:
        this.delete(move)
        break
      case ItemMoveType.Shuffle:
        this.shuffle(move)
        break
    }
  }

  private findMergeIndex(newItem: MaterialItem<P, L>): number {
    if (this.childrenLocations.length > 0) return -1 // Never merge items that can have other items on them
    return this.items.findIndex(item => itemsCanMerge(item, newItem))
  }

  private addItem(item: MaterialItem<P, L>): number {
    this.applyAddItemStrategy(item)
    const availableIndex = this.items.findIndex(item => item.quantity === 0)
    if (availableIndex !== -1) {
      this.items[availableIndex] = item
      return availableIndex
    } else {
      this.items.push(item)
      return this.items.length - 1
    }
  }

  private applyAddItemStrategy(item: MaterialItem<P, L>): void {
    if (item.location.type in this.locationsStrategies) {
      const strategy = this.locationsStrategies[item.location.type]!
      if (strategy.addItem) {
        const material = new Material(this.type, Array.from(this.items.entries()).filter(entry => entry[1].quantity !== 0))
          .location(item.location.type).player(item.location.player).locationId(item.location.id).parent(item.location.parent)
        strategy.addItem(material, item)
      }
    }
  }

  private removeItem(item: MaterialItem<P, L>, quantity: number = 1) {
    item.quantity = Math.max(0, (item.quantity ?? 1) - quantity)
    if (item.quantity === 0) {
      this.applyRemoveItemStrategy(item)
    }
  }

  private applyRemoveItemStrategy(item: MaterialItem<P, L>): void {
    if (item.location.type in this.locationsStrategies) {
      const strategy = this.locationsStrategies[item.location.type]!
      if (strategy.removeItem) {
        const material = new Material(this.type, Array.from(this.items.entries()).filter(entry => entry[1].quantity !== 0))
          .location(item.location.type).player(item.location.player).locationId(item.location.id).parent(item.location.parent)
        strategy.removeItem(material, item)
      }
    }
  }

  create(move: CreateItem<P, M, L>): number {
    const mergeIndex = this.findMergeIndex(move.item)
    if (mergeIndex !== -1) {
      const mergeItem = this.items[mergeIndex]
      mergeItem.quantity = (mergeItem.quantity ?? 1) + (move.item.quantity ?? 1)
      return mergeIndex
    } else {
      return this.addItem(move.item)
    }
  }

  move(move: MoveItem<P, M, L>): number {
    const quantity = move.quantity ?? 1
    const sourceItem = this.items[move.itemIndex]
    const itemAfterMove = this.getItemAfterMove(move)
    const mergeIndex = this.findMergeIndex(itemAfterMove)
    if (mergeIndex !== -1) {
      const mergeItem = this.items[mergeIndex]
      mergeItem.quantity = (mergeItem.quantity ?? 1) + quantity
      this.removeItem(sourceItem, quantity)
      return mergeIndex
    } else if (sourceItem.quantity && sourceItem.quantity > quantity) {
      sourceItem.quantity -= quantity
      return this.addItem(itemAfterMove)
    } else {
      if (move.position.location) {
        this.applyAddItemStrategy(itemAfterMove)
      }
      this.items[move.itemIndex] = itemAfterMove
      if (move.position.location) {
        this.applyRemoveItemStrategy(sourceItem)
      }
      return move.itemIndex
    }
  }

  getItemAfterMove(move: MoveItem<P, M, L>): MaterialItem<P, L> {
    const { quantity, rotation, ...itemData } = this.items[move.itemIndex]
    const item: MaterialItem<P, L> = { ...itemData, ...JSON.parse(JSON.stringify(move.position)) }
    if (typeof move.reveal === 'object') { // keep typeof for legacy reason when reveal could be a boolean
      merge(item, move.reveal)
    }
    if (move.quantity) item.quantity = move.quantity
    return item
  }

  private delete(move: DeleteItem<M>): void {
    return this.removeItem(this.items[move.itemIndex]!, move.quantity)
  }

  private shuffle(move: Shuffle<M> | ShuffleRandomized<M>): void {
    if (!isShuffleRandomized(move)) return // Nothing to do on front-end side for a shuffle. The index swap is only required on the backend.
    const shuffledItems = move.indexes.map((index) => this.items[index])
    move.newIndexes.forEach((newIndex, i) => {
      this.items[newIndex] = { ...shuffledItems[i], location: this.items[newIndex].location }
    })
  }
}

export const itemsCanMerge = ({ quantity: q1, ...data1 }: MaterialItem, { quantity: quantityB, ...data2 }: MaterialItem): boolean => equal(data1, data2)
