import { PropsWithChildren, useMemo } from 'react'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import { createAnimationsListener } from '../../animations'
import { gameMiddleware } from '../../Store/gameMiddleware'
import { gameReducer, GameReducerContext } from '../../Store/gameReducer'
import { consoleTools } from './ConsoleTools'
import { GameLocalAPI } from './GameLocalAPI'
import { GameSetupCreator, Robot, RulesCreator } from '@gamepark/rules-api'
import { TutorialDescription } from '../../Tutorial'
import { GameAI } from '../../Types'

export type LocalGameProviderProps<Game = any, GameView = any, Move = any, MoveView = any, PlayerId = any> = {
  game: string
  ServerRules?: RulesCreator<Game, Move, PlayerId>
  GameSetup?: GameSetupCreator<Game>
  optionsSpec?: any
  dummy?: Robot<Game, Move, PlayerId>
  tutorial?: TutorialDescription<Game, Move, PlayerId>,
  ai?: GameAI<Game, Move, PlayerId>
} & GameReducerContext<GameView, MoveView, PlayerId>

export function LocalGameProvider<Game = any, GameView = any, Move = any, MoveView = any, PlayerId = any>(
  { children, ...props }: PropsWithChildren<LocalGameProviderProps<Game, GameView, Move, MoveView, PlayerId>>
) {
  const store = useMemo(() => createLocalApiStore(props), [])
  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
}

function createLocalApiStore(context: LocalGameProviderProps) {
  const api = new GameLocalAPI(context)
  const reducer = gameReducer(context)
  const store = createStore(reducer, applyMiddleware(gameMiddleware(api)))
  store.subscribe(createAnimationsListener(store))
  if (process.env.NODE_ENV === 'development') {
    window.game = consoleTools(api, store)
  }
  api.init(store.dispatch)
  return store
}
