import { Middleware } from 'redux'
import { ActionType, MOVE_UNDONE } from '../Actions'
import { GameAPI } from '../api/GameAPI'
import { EJECT_PLAYER, EjectPlayer } from '../EjectPlayer'
import { GIVE_UP } from '../GiveUp'
import { PLAY_TUTORIAL_MOVES, PlayTutorialMoves } from '../Tutorial/PlayTutorialMoves'
import { findLastIndex } from '../util/ArrayUtil'
import { GamePageState } from './GamePageState'
import { GameActions } from './gameReducer'

export function gameMiddleware<Game, Move, PlayerId>(api: GameAPI<Move, PlayerId>)
  : Middleware<{}, GamePageState<Game, Move, PlayerId>> {
  return store => next => (event: GameActions | EjectPlayer | PlayTutorialMoves) => {
    switch (event.type) {
      case ActionType.MOVE_PLAYED:
        if (!event.local) {
          api.play(event.move)
        }
        break
      case MOVE_UNDONE:
        const actions = store.getState().actions
        if (!actions) return console.error('gameMiddleware - MOVE_UNDONE: Actions is missing')
        const index = findLastIndex(actions, action => action.id === event.actionId)
        if (index === -1) return console.error('gameMiddleware - MOVE_UNDONE: Action not found')
        api.undo(event.actionId)
        break
      case EJECT_PLAYER:
        api.eject(event.playerId)
        break
      case GIVE_UP:
        api.giveUp()
        break
      case PLAY_TUTORIAL_MOVES:
        api.playTutorialMoves(event.quantity)
        break
    }
    return next(event)
  }
}
