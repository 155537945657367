import { ItemLocator } from '@gamepark/react-game'
import { MaterialType } from '@gamepark/the-witcher-old-world-monster/material/MaterialType'
import { LocationType } from '@gamepark/the-witcher-old-world-monster/material/LocationType'
import { MonsterDeckLocator } from './MonsterDeckLocator'
import { MonsterDiscardLocator } from './MonsterDiscardLocator'

export const Locators: Record<LocationType, ItemLocator<number, MaterialType, LocationType>> = {
  [LocationType.Deck]: new MonsterDeckLocator(),
  [LocationType.Discard]: new MonsterDiscardLocator()
}
