import { GamePageState } from '../Store'

export const GIVE_UP = 'GIVE_UP'

export type GiveUp = { type: typeof GIVE_UP }

export const giveUpAction = { type: GIVE_UP }

export function giveUp(state: GamePageState) {
  return { ...state, playerId: undefined }
}